import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import { ContentWrapper } from '../components/content_wrapper/content_wrapper';
import { SEO } from '../components/seo';
import { GatsbyPageProps } from '../gatsby-types';
import Layout from '../layouts/layout';
import { linkResolver } from '../lib/prismic';
import { PrismicSliceText } from '../models/prismic';

export const query = graphql`
  {
    prismicGiveawaysPage {
      data {
        body {
          ... on PrismicGiveawaysPageDataBodyText {
            id
            slice_label
            slice_type
            primary {
              text {
                raw
              }
            }
          }
        }
      }
    }
  }
`;

const GiveawaysPage = (props: GatsbyPageProps) => {
  const page = props.data.prismicGiveawaysPage.data;
  return (
    <Layout>
      <SEO title="Giveaways" />
      <ContentWrapper>
        <article>
          {page.body.map((slice: PrismicSliceText, i: number) => {
            switch (slice.slice_type) {
              case 'text': {
                return (
                  <React.Fragment key={i}>
                    <RichText render={slice.primary.text.raw} linkResolver={linkResolver} />
                  </React.Fragment>
                );
              }
            }
          })}
        </article>
      </ContentWrapper>
    </Layout>
  );
};

export default GiveawaysPage;
