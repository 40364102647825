import { Document } from 'prismic-javascript/types/documents';
import { RoutePaths } from './routes';

export enum PrismicDocumentTypes {
  ARTICLE = 'article',
}

export const linkResolver = (doc: Document) => {
  const docType = doc.type as PrismicDocumentTypes;
  if (docType === PrismicDocumentTypes.ARTICLE) {
    return `${RoutePaths.ARTICLES}${doc.uid}/`;
  }

  throw new Error('Unknown document type encountered');
};
